.button {
    max-width: 20.5rem;
    text-align: center;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: var(--color-text-dark-solid);
    background-color: var(--color-primary);
    border-radius: 99999px;
    padding: 0.75rem;
    font-family: 'Roboto Mono';
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 140%;
    transition: 0.2s ease;

    &:active {
        background-color: var(--color-primary-active);

        span svg path {
            fill: var(--color-primary-active);
        }
    }

    &:disabled {
        pointer-events: none;
    }
    span {
        display: flex;
        align-items: center;

        line-height: 140%;

        svg {
            width: 1rem;
            height: 1rem;
            margin-right: 0.375rem;
            margin-top: 1px;
            circle {
                fill: var(--color-text-dark-solid);
            }
            path {
                fill: var(--color-primary);
            }
        }
    }
}

.disabled {
    background-color: var(--color-background);
    color: var(--color-text-disabled);

    span {
        color: var(--color-text-disabled);
        svg {
            circle {
                fill: var(--color-text-disabled);
            }
            path {
                fill: var(--color-background);
            }
        }
    }
}

.label {
    font-family: 'Roboto Mono';
    text-align: right;
    justify-content: flex-end;
    font-size: 1.125rem;
}

.amount {
    font-family: 'Druk Wide';
    font-weight: 600;
    text-align: left;
    justify-content: flex-start;
}
