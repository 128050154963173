.wrapper {
    display: flex;
    align-items: center;
    max-width: 12rem;
    border-radius: 999px;
    backdrop-filter: var(--bg-blur);
    -webkit-backdrop-filter: var(--bg-blur);
    background-color: rgba(26, 26, 26, 0.4);
}

.segment {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    font-size: 0.875rem;
    font-family: 'Roboto Mono';
    font-weight: 700;

    &:first-child {
        border-right: solid 0.5px var(--color-border);
    }

    &:last-child {
        margin-right: 4px;
    }

    .icon {
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 999%;
        background: #000;
        text-align: center;
        line-height: 1.5rem;
        margin-right: 0.25rem;
    }
}
