.button {
    max-width: 20.5rem;
    width: 100%;
    height: 3.0625rem;
    padding: 0.75rem 0;
    border-radius: 999px;
    background-color: var(--color-primary);
    color: var(--color-text-dark-solid);
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 140%;
    font-family: 'Roboto Mono';
    border: solid 1px var(--color-primary);

    &:active {
        background-color: var(--color-primary-active);
    }
}

.progress {
    color: var(--color-text-light);
    background-color: inherit;
    border: solid 1px var(--color-border);
}
