.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .roachContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;

        img {
            height: 66vh;
        }
    }

    .bottom {
        width: 100%;
        position: absolute;
        bottom: 0;

        .rowContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .buttonContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.5rem 0;
        }
    }
}
