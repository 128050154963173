.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    z-index: 70;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: opacity 1s ease;
    pointer-events: none;

    &.active {
        opacity: 1;
        pointer-events: unset;
    }

    .textContainer {
        margin-top: 6.125rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        h3 {
            font-weight: 700;
            font-size: 1.25rem;
        }
        .countdown {
            color: var(--color-text-secondary);
            font-family: 'Roboto Mono';
            font-weight: 600;
        }

        p {
            color: var(--color-text-secondary);
            font-family: 'Roboto Mono';
            font-weight: 400;
            font-size: 0.9375rem;
        }
    }
    .buttonContainer {
        position: absolute;
        bottom: 1.5rem;
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: center;
    }
}
