.layer {
    opacity: 0;
    transition: opacity 1s ease;
    pointer-events: none;

    &.active {
        opacity: 1;
        pointer-events: unset;
    }
}

.animated {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
    animation: fade-out 0.75s forwards;
    position: absolute;
    top: var(--effect-top);
    left: var(--effect-left);
    z-index: 50;
    pointer-events: none;

    --effect-top: 0px;
    --effect-left: 0px;
    --effect-top: 0px;
    --effect-top: 0px;
}

@keyframes fade-out {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    25% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-5rem);
    }
}
