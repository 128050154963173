:root {
    --color-background: #1a1a1a;
    --color-box: #0a0a0a;
    --color-border: #2b2b2b;
    --color-text-secondary: #b3b3b3;
    --color-text-dark-solid: #000000;
    --color-text-light: #ffff;
    --color-primary: #3edc03;
    --color-primary-active: rgba(62, 220, 3, 0.8);
    --color-notification: #f63838;
    --color-background-modal: rgba(0, 0, 0, 0.6);
    --color-text-disabled: #848484;
    --bg-blur: blur(4px);
}
