.button {
    height: 2.5rem;
    width: 7.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 8px 12px;
    color: var(--color-primary);
    border: solid 1px var(--color-primary);
    font-family: 'Roboto Mono';
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    border-radius: 999px;
    backdrop-filter: var(--bg-blur);
    -webkit-backdrop-filter: var(--bg-blur);
    background-color: rgba(26, 26, 26, 0.4);
}
