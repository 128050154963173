.overlayContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-background-modal);
    z-index: 100;
    padding: 1rem;
    backdrop-filter: var(--bg-blur);
    -webkit-backdrop-filter: var(--bg-blur);
    pointer-events: none;

    .textContainer {
        margin-top: 4.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        text-align: center;

        h3 {
            font-size: 1.25rem;
        }
        p {
            color: var(--color-text-secondary);
            font-size: 1rem;
            line-height: 140%;
            font-family: 'Roboto Mono';
        }
    }
    .tapContainer {
        position: absolute;
        top: 42%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .tap {
            font-size: 1.625rem;
            animation: blink 3s infinite ease-in-out 2s;
        }
    }
}

@keyframes blink {
    0% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
    50% {
        opacity: 0.3;
        transform: translateY(0.225rem) scale(0.98);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}
