.badge {
    position: absolute;
    top: -0.125rem;
    right: -0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 0.875rem;
    padding: 3px 4px;
    height: 15px;
    font-size: 11px;
    line-height: 110%;
    background-color: var(--color-notification);
    color: var(--color-text-light);
    font-weight: 500;
    font-family: 'Roboto Mono', sans-serif;
    border-radius: 9999px;
}
