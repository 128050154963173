@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./styles/colors.scss);

body {
    margin: 0;
    font-family:
        'Druk Wide',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000000;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.slider-horizontal {
    bottom: -147px !important;
    position: fixed !important;
    z-index: 555 !important;
    color: red;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Druk Wide';
    src:
        local('Druk Wide Regular'),
        url('./fonts/druk-wide/Druk-Wide-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Druk Wide';
    src:
        local('Druk Wide Bold'),
        url('./fonts/druk-wide/Druk-Wide-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Medium'),
        url('./fonts/roboto-mono/roboto-mono-medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Medium Italic'),
        url('./fonts/roboto-mono/roboto-mono-medium-italic.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Thin'),
        url('./fonts/roboto-mono/roboto-mono-thin.woff') format('woff');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Light'),
        url('./fonts/roboto-mono/roboto-mono-light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Bold'),
        url('./fonts/roboto-mono/roboto-mono-bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Regular'),
        url('./fonts/roboto-mono/roboto-mono-regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Light Italic'),
        url('./fonts/roboto-mono/roboto-mono-light-italic.woff') format('woff');
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Thin Italic'),
        url('./fonts/roboto-mono/roboto-mono-thin-italic.woff') format('woff');
    font-style: italic;
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Regular Italic'),
        url('./fonts/roboto-mono/roboto-mono-regular-italic.woff') format('woff');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto Mono';
    src:
        local('Roboto Mono Bold Italic'),
        url('./fonts/roboto-mono/roboto-mono-bold-italic.woff') format('woff');
    font-style: italic;
    font-weight: 700;
}

body,
html {
    position: fixed;
    overflow: hidden;
}

:root {
    --tg-theme-header-bg-color: #000000;
    --tg-theme-header-text-color: #ffffff;
    --tg-theme-secondary-bg-color: #000000;
    --tg-theme-bg-color: #000000;
    --tg-theme-section-bg-color: #000000;
}
