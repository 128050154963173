.wrapper {
    margin-top: 0.375rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--color-background);
    border-radius: 999px;

    .segment {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 8px;
        font-size: 0.75rem;
        font-family: 'Roboto Mono';
        font-weight: 700;
        min-width: 5.85rem;

        &:first-child {
            margin-right: 0.375rem;
        }

        &:last-child {
            padding-left: 0.5rem;
            margin-right: 0.3rem;
        }
    }

    .balance {
        min-width: 8.125rem;
        border-left: solid var(--color-border);
        border-right: solid var(--color-border);
        font-size: 1rem;
        line-height: 2rem;
        font-family: 'Druk Wide';
        gap: 0.5rem;
        padding: 2px 10.5px;
    }

    .coin {
        height: 1rem;
        width: 1rem;
    }

    .icon {
        height: 1rem;
        width: 1rem;
        border-radius: 999%;
        background: #000;
        text-align: center;
        line-height: 1rem;
        margin-right: 0.25rem;
    }

    .endChars {
        color: var(--color-text-secondary);
    }
}
